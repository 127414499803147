// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1pSkrd-aG8YjhasW5LJaTj{margin-top:70px}._1pSkrd-aG8YjhasW5LJaTj ._164aqNTkqkowCsiv4eKoSl{display:flex;flex-direction:column;align-items:stretch}@media only screen and (max-width: 955px){._1pSkrd-aG8YjhasW5LJaTj{margin-top:62px}}", ""]);
// Exports
exports.locals = {
	"Landing": "_1pSkrd-aG8YjhasW5LJaTj",
	"Content": "_164aqNTkqkowCsiv4eKoSl"
};
module.exports = exports;
